import React, { ReactElement } from 'react';
import { Form, Formik } from 'formik';
import { TFunction, useTranslation } from 'gatsby-plugin-react-i18next';

// Utils
import { FORGOT_PASSWORD_SCHEMA } from 'schema/forgot-password';

// UI Kit & components
import { Container, Row, Col } from 'react-bootstrap';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import Button from 'ui-kit/button/button';
import Text from 'ui-kit/text/text';
import Link from 'ui-kit/link/link';

import { ForgotPasswordFormValues, ForgotPasswordProps } from './forgot-password-form.props';

export const ForgotPasswordFailureModalContent = ({ translation }: { translation: TFunction<'translation'> }) => {
    return (
        <BirdiModalContent
            icon="alert"
            title={translation('modals.forgotPasswordFailure.title')}
            body={translation('modals.forgotPasswordFailure.body')}
        />
    );
};

const ForgotPasswordForm = ({ isFetchingSecurityQuestions, onSubmit, style }: ForgotPasswordProps): ReactElement => {
    const { t } = useTranslation();
    const formName = 'ForgotPasswordForm';
    const handleSubmit = (values: Partial<ForgotPasswordFormValues>) => {
        onSubmit(values);
    };

    return (
        <Container fluid style={style}>
            <Row>
                <Col className="d-flex flex-column align-items-center">
                    <p>{t('forgotPassword.labels.formInstructions')}</p>
                    <Formik<Partial<ForgotPasswordFormValues>>
                        onSubmit={handleSubmit}
                        validationSchema={FORGOT_PASSWORD_SCHEMA}
                        initialValues={{
                            username: undefined
                        }}
                    >
                        {(formik: any) => (
                            <Form
                                id="forgot-password-form"
                                data-ga-form-name={formName}
                                onSubmit={formik.handleSubmit}
                                autoComplete="off"
                            >
                                <Text
                                    label={t('forgotPassword.labels.username')}
                                    name="username"
                                    onChange={formik.handleChange}
                                    errors={formik.errors?.username ? t('forgotPassword.errors.username') : undefined}
                                    touched={formik.touched.username}
                                    value={formik.values?.username}
                                    defaultValue={formik.initialValues?.username}
                                />
                                <div className="d-flex flex-column align-items-center">
                                    <Button
                                        async
                                        className="sm-full mw-100"
                                        label={t('forgotPassword.labels.submit')}
                                        type="submit"
                                        isBusy={isFetchingSecurityQuestions}
                                        dataGAFormName={formName}
                                    />
                                    <Link
                                        className="d-inline-block mt-4"
                                        to="/sign-in"
                                        label={t('pages.profile.messages.labels.cancel')}
                                        variant="underline-blue-bold"
                                        dataGALocation="loginIssues"
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default ForgotPasswordForm;
